import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Button from "../components/Button/Button";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import ReviewGuideContent from "../components/ReviewGuideContent/ReviewGuideContent";

import "./ToolPage.css";
import { GlobalStateContext } from "../context/GlobalContextProvider";

import slugGenerator from "../util/slugGenerator";

import YoutubeButton from "./youtube-button.png";

const ToolPage = ({ location, data }) => {
  const state = React.useContext(GlobalStateContext);

  const [showVideo, setShowVideo] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const aiTool = data.aiTool;

  // Get the current path
  const currentPath = location.pathname;

  let listOfTags = aiTool.aiToolTags.map((aiToolTag) => aiToolTag.tag.title);

  let links = [
    {
      title: "HOME",
      to: "/",
    },
  ];

  if (aiTool.aiToolCategories && aiTool.aiToolCategories.length > 0) {
    const aiToolCategory = aiTool.aiToolCategories[0];
    links.push({
      title: aiToolCategory.category.title,
      to: `/${slugGenerator(aiToolCategory.category.title)}/`,
    });
  }

  links.push({
    title: `${aiTool.title} Review`.toUpperCase(),
  });

  let content = [];
  try {
    content = JSON.parse(aiTool.review);
    content.shift();
  } catch (e) {
    // console.log(e);
  }

  let headerCount = 1;
  const tableOfContents = [];

  if (content) {
    // Extract headings and build table of contents
    content.forEach((item) => {
      if (item.content && item.content.heading) {
        const id = `header-${headerCount++}`;
        tableOfContents.push({ text: item.content.heading, id });
        item.content.id = id;
      }
    });
  }

  React.useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const sections = document.querySelectorAll("h2[id]");

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;

        if (rect.top <= 0 && rect.bottom > 0) {
          setActiveItem(section.id);
        }
      });
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTableOfContentsItemClick = (id) => {
    setActiveItem(id);
  };

  return (
    <Page>
      <TopBar showSearch={true} />
      {aiTool.title && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            maxWidth: "1500px",
            margin: "0 auto",
          }}
        >
          <div>
            <Section
              style={{
                color: "#fff",
                lineHeight: "27px",
              }}
            >
              <Breadcrumbs links={links} />
              <div className="tool-profile">
                <div className="tool-profile-left">
                  <SectionHeading>
                    <span style={{ color: "#3578A0" }}>
                      {(aiTool && aiTool.reviewTitle) ||
                        `${aiTool.title} | The Best AI Tool Ever?`.toUpperCase()}
                    </span>
                  </SectionHeading>
                  {aiTool.summaryDescription && (
                    <div className="tool-description">
                      {aiTool.summaryDescription}
                    </div>
                  )}
                  <div className="tool-buttons">
                    {aiTool.affiliateUrl && (
                      <a
                        href={aiTool.affiliateUrl}
                        target="_blank"
                        rel="nofollow"
                      >
                        <Button>VIEW PRODUCT</Button>
                      </a>
                    )}
                    {/* <Link to={`/${aiTool.slug}-coupon-codes/`}>
                    <Button>VIEW COUPONS</Button>
                  </Link> */}
                    {aiTool.hasGuide && (
                      <Link to={`/how-to-use-${aiTool.slug}/`}>
                        <Button>VIEW OUR GUIDE</Button>
                      </Link>
                    )}
                  </div>
                </div>

                <div className="tool-profile-right">
                  {!showVideo && (
                    <>
                      <img
                        src={aiTool.screenshot}
                        className="tool-screenshot"
                        alt={aiTool.title}
                      />
                      {aiTool.youtubeUrl && (
                        <div className="youtube-button-wrapper">
                          <img
                            src={YoutubeButton}
                            onClick={() => setShowVideo(true)}
                            className="youtube-button"
                          />
                        </div>
                      )}
                    </>
                  )}
                  {showVideo && (
                    <iframe
                      width="100%"
                      height="400px"
                      src={`${aiTool.youtubeUrl}?autoplay=1`}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen
                      className="tool-video"
                    ></iframe>
                  )}
                  <div className="tool-page-tags">
                    {listOfTags.map((tag) => (
                      <Link to={`/${slugGenerator(tag)}/`}>
                        <div className="tag">{tag}</div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </Section>
            {content && (
              <Section backgroundColor="#fafafa">
                <div className="table-of-contents mobile">
                  <div className="table-of-contents-wrapper">
                    <div className="table-of-contents-title">
                      <span>Table of Contents</span>
                    </div>
                    <ul>
                      {tableOfContents.map(({ text, id }) => (
                        <li key={id}>
                          <Link to={`#${id}/`}>{text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <ReviewGuideContent content={content} contentType="review" />
              </Section>
            )}
          </div>
          {content && (
            <div className="table-of-contents desktop">
              <div className="table-of-contents-wrapper">
                <div className="table-of-contents-title">
                  <span>Table of Contents</span>
                </div>
                <ul>
                  {tableOfContents.map(({ text, id }) => (
                    <li key={id}>
                      <Link
                        to={`#${id}`}
                        className={activeItem === id ? "active" : ""}
                        onClick={() => handleTableOfContentsItemClick(id)}
                      >
                        {text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
      <Footer />
    </Page>
  );
};

export default ToolPage;

export const Head = ({ data }) => {
  const location = useLocation();

  const aiTool = data.aiTool;

  return (
    <SEO
      index
      follow
      title={aiTool.metaTitle || `${aiTool.title} | The Best AI Tool Ever?`}
      description={
        aiTool.metaDescription ||
        `Don't sign up until you've read our ${aiTool.title} review. We will tell you about it's features, what we loved, what we didn't, and give you our honest opinion.`
      }
      pathname={location.pathname}
      image={aiTool.screenshot}
    />
  );
};

export const query = graphql`
  query ToolPageQuery($slug: String!) {
    aiTool(slug: { eq: $slug }) {
      id
      slug
      title
      review
      reviewTitle
      screenshot
      hasGuide
      websiteUrl
      affiliateUrl
      youtubeUrl
      summaryDescription
      metaTitle
      metaDescription
      aiToolCategories {
        categoryId
        category {
          title
        }
      }
      aiToolTags {
        tagId
        tag {
          title
        }
      }
    }
  }
`;
